
.invert{
    filter: invert(100%);
}
@keyframes line {
    0% {
        bottom: 100vh;
    }
    35% {
        bottom: 16vh;
    }
    80% {
        bottom: 16vh;
    }
    100%{
        bottom: 6vh;
    }
}

@keyframes whatsapp-line {
    0% {
        bottom: 12vh;
    }
    80% {
        bottom: 5.5em;
    }
    100%{
        bottom: 1em;
    }
}

@keyframes line-with-circul {
    0% {
        bottom: 120vh;
    }
    100%{
        bottom: 0vh;
    }
}

.side-line{
    width: 4em;
    position: fixed;
    z-index: 7;
    left: 0.5vw;
    bottom: 0vh;
    transition: bottom 0.1s ease;
    animation: line-with-circul 2s ease;
}

.side-line .circul{
    position: absolute;
    z-index: 4;
    width: 100%;
    left: 0;
    bottom: 1em;
    cursor: pointer;
    /* animation: phone-line 2s ease; */
}

.side-line .circul.whatsapp{
    z-index: 3;
    animation: whatsapp-line 2s ease;
}

.side-line .circul.phone{
    bottom: 5.5em;
    /* animation: whatsapp-line 2s ease; */
}

.side-line .circul a {
    display: flex;
    text-decoration: none;
}

.side-line .circul.phone > a > span {
    white-space: nowrap;
    margin: auto;
    color: white;
    font-family: 'Metropolis-Bold';
    margin-left: 1rem;
    opacity:0;
    transition: all 0.5s ease-in-out;
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.phone-icon:hover ~ .phone-number{
    opacity: 1 !important;
}

.side-line a img{
    width: auto;
    height: 4em;
}


.side-line .main-line{
    display: flex;
    justify-content: space-evenly;
    z-index: 2;
    width: 110vh;
    height: auto;
    position: relative;
    bottom: 6vh;
    left: 50%;
    transform-origin: center left;
    transform: rotate(-90deg);
    animation: line 2s ease;
}

.side-line .main-line .inline-text{
    letter-spacing: 0.5rem;
    z-index: 2;
    color: #fff;
    font-family: 'Metropolis-Light';
    text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}
.side-line .main-line .line{
    flex: 1 0;
    height: 0.1rem;
    background-color: #777;
    margin: auto 0.5rem auto 0rem;
}



.omg-website{
    padding-top: 15vh;
}

.omg{
    position: relative;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    color: #fff;;
    justify-content: center;
    font-size: 2.5rem;
    align-items: center;
    font-family: 'Metropolis-Bold';
}
.omg .we {
    margin-right: 0.4em;
}
.omg .bold{
    
    text-align: center;
    position: relative;

}

@keyframes rolling {
    0%,22.5%{
        transform: perspective(10em) rotateX(90deg) translateZ(1em);
        opacity: 0;
    }
    27.5%,72.5%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
    77.5%,100%{
        transform: perspective(10em) rotateX(-90deg) translateZ(1em);
        opacity: 0;
    }
}
@keyframes rollingPuls90degs {
    0%,22.5%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
    27.5%,72%{
        transform: perspective(10em) rotateX(-90deg) translateZ(1em);
        opacity: 0;
    }
    72.5%{
        transform: perspective(10em) rotateX(90deg) translateZ(1em);
        opacity: 0;
    }
    77.5%,100%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
}
.omg .bold .design{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transform: perspective(10em) rotateX(85deg) translateZ(1em);
    animation: rolling 3s infinite;
    letter-spacing: 0.1em;
    opacity: 0;
}
.omg .bold .develop{
    position: relative;
    animation: rollingPuls90degs 3s infinite;
}
@keyframes rolling-light-website {
    0%,22.5%,90%,100%{
        transform: perspective(10em) rotateX(90deg) translateZ(1em);
        opacity: 0;
    }
    27.5%,80%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
}
@keyframes rolling-light-webapp {
    0%,22.5%,100%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
    27.5%,80%{
        transform: perspective(10em) rotateX(-90deg) translateZ(1em);
        opacity: 0;
    }
    95%{
        transform: perspective(10em) rotateX(90deg) translateZ(1em);
        opacity: 0;
    }
}
@keyframes rolling-light-web {
    0%,60%,100%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
    65%, 94%{
        transform: perspective(10em) rotateX(-90deg) translateZ(1em);
        opacity: 0;
    }
    95%{
        transform: perspective(10em) rotateX(90deg) translateZ(1em);
        opacity: 0;
    }
}
@keyframes rolling-light-mobile {
    0%,60%{
        transform: perspective(10em) rotateX(90deg) translateZ(1em);
        opacity: 0;
    }
    65%, 95%{
        transform: perspective(10em) rotateX(0deg) translateZ(0em);
        opacity: 1;
    }
    100%{
        transform: perspective(10em) rotateX(-90deg) translateZ(1em);
        opacity: 0;
    }
}

.omg .light{
    font-family: 'Metropolis-light';
    position: relative;
}
.omg .light .web{
    position: absolute;
    top: 0;
    left: 1.2em;
    display: flex;
    animation: rolling-light-web 4s infinite;
}
.omg .light .inner-web{
    position: relative;
}
.omg .light .web .site{
    animation: rolling-light-website 4s infinite;
}
.omg .light .web .app{
    position: absolute;
    left: 0.3em;
    top: 0;
    animation: rolling-light-webapp 4s infinite;
}
.omg .mobile{
    animation: rolling-light-mobile 4s infinite;
    position: relative;
}
.omg .slot-word{
    display: flex;
    font-size: 3.5em;
    font-family: 'Metropolis-Bold';
    overflow: hidden;
    color: #DD4FE9;
    margin: 0em 0.2em 0.1em 0.2em;
    line-height: 1em;
}
.omg .letter-wrapper{
    position: relative;
}
.omg .letter-wrapper.letter-o{
    transform: translate(0, 0.07em);
}
.omg .letter-wrapper.letter-m{
    transform: translate(0, 0.07em);
}
.omg .letter-wrapper.letter-g{
    transform: translate(0, 0.07em);
}
.omg .letter-wrapper.letter-o.animate{
    animation: slot-o-Rolling 3s  ease-out forwards;
}
.omg .letter-wrapper.letter-m.animate{
    animation: slot-m-Rolling 3s  ease-out forwards;
}
.omg .letter-wrapper.letter-g.animate{
    animation: slot-g-Rolling 3s  ease-out forwards;
}
@keyframes slot-m-Rolling {
    0.001%, 2.501%, 5.001%, 7.501%, 10.001%, 12.501%, 15.001%, 17.501%, 20.001%, 22.501%, 25.001%, 27.501%, 30.001%, 32.501%{
        transform: translate(0, -0.83em);
    }
            2.5%,   5%,     7.5%,    10%,    12.5%,   15%,     17.5%,   20%,     22.5%,   25%,     27.5%,   30%,     32.5%,   35%{
        transform: translate(0, 1.57em);
    }

    35.001%, 40.001%, 45.001%, 50.001%{
        transform: translate(0, -0.83em);
    }
             40%,     45%,     50%,     55%{
        transform: translate(0, 1.57em);
    }

    55.001%, 65.001%, 75.001% {
        transform: translate(0, -0.83em);
    }
             65%,     75%,    85%,   87%{
        transform: translate(0, 1.57em);
    }

    90%{
        transform: translate(0, 1em);
    }
    92.5%{
        transform: translate(0, 0.5em);
    }
    95%{
        transform: translate(0, 0.1em);
    }
    100%{
        transform: translate(0, 0.07em);
    }
    /* 90%{
        transform: translate(0, 0.1em);
    }
    92.5%{
        transform: translate(0, 0.35em);
    }
    95%, 100%{
        transform: translate(0, 0.07em);
    } */
}
@keyframes slot-o-Rolling {
    0.001%, 2.501%, 5.001%, 7.501%{
        transform: translate(0, -0.83em);
    }
            2.5%,   5%,     7.5%,    10%{
        transform: translate(0, 1.57em);
    }
    
    10.001%, 15.001%, 20.001%{
        transform: translate(0, -0.83em);
    }
             15%,     20%,     25%{
        transform: translate(0, 1.57em);
    }

    25.001%, 35.001%, 45.001%, 55.001% {
        transform: translate(0, -0.83em);
    }
             35%,     45%,     55%,   65%{
        transform: translate(0, 1.57em);
    }
    67.5%{
        transform: translate(0, 1.58em);
    }
    70%{
        transform: translate(0, 1.3em);
    }
    72.5%{
        transform: translate(0, 1em);
    }
    75%{
        transform: translate(0, 0.5em);
    }
    77.5%{
        transform: translate(0, -0.2em);
    }
    80%, 100%{
        transform: translate(0, 0.07em);
    }
}
@keyframes slot-g-Rolling {
    0.001%, 2.501%{
        transform: translate(0, -0.83em);
    }
            2.5%,   5%{
        transform: translate(0, 1.57em);
    }
    
    5.001%{
        transform: translate(0, -0.83em);
    }
             10%{
        transform: translate(0, 1.57em);
    }

    10.001%{
        transform: translate(0, -0.83em);
    }
             20%{
        transform: translate(0, 1.57em);
    }

    20.001%{
        transform: translate(0, -0.83em);
    }
             25%{
        transform: translate(0, 1.57em);
    }

    25.001%, 27.501%{
        transform: translate(0, -0.83em);
    }
            27.5%,   30%{
        transform: translate(0, 1.57em);
    }

    30.001%{
        transform: translate(0, -0.83em);
    }
             35%{
        transform: translate(0, 1.57em);
    }

    35.001%{
        transform: translate(0, -0.83em);
    }
             45%{
        transform: translate(0, 1.57em);
    }

    45.001%{
        transform: translate(0, -0.83em);
    }
             50%{
        transform: translate(0, 1.57em);
    }

    50.001%, 52.501%{
        transform: translate(0, -0.83em);
    }
            52.5%,   55%{
        transform: translate(0, 1.57em);
    }

    55.001%{
        transform: translate(0, -0.83em);
    }
             60%{
        transform: translate(0, 1.57em);
    }

    60.001%{
        transform: translate(0, -0.83em);
    }
             70%{
        transform: translate(0, 1.57em);
    }

    70.001%{
        transform: translate(0, -0.83em);
    }
             85%{
        transform: translate(0, 1.57em);
    }

    85.001%{
        transform: translate(0, -0.83em);
    }
             95%{
        transform: translate(0, 1.57em);
    }
    96%{
        transform: translate(0, 0.75em);
    }
    97%{
        transform: translate(0, 0.0em);
    }
    98%{
        transform: translate(0, -0.1em);
    }
    99%{
        transform: translate(0, 0.3em);
    }
    100%{
        transform: translate(0, 0.07em);
        /* transform: translate(0, 0.19em); */
    }
}
.omg .letter-wrapper .mask{
    position: absolute;
    opacity: 0.7;
}
.omg .letter-wrapper .upper-mask{
    top: -0.8em;
    width: 0.81em;
    height: 1em;
}
.omg .letter-wrapper .lower-mask{
    left: 0;
    top: 0.8em;
    width: 0.81em;
    height: 1em;
}

.omg .letter-wrapper .upper-mask2{
    top: -1.6em;
    width: 0.81em;
    height: 1em;
}
.omg .letter-wrapper .lower-mask2{
    left: 0;
    top: 1.6em;
    width: 0.81em;
    height: 1em;
}
.omg .slot-word .letter-wrapper > span {
    color: #2B47F0;
}
.omg-p{
    color: white;
    position: relative;
    z-index: 3;
    font-family: 'Metropolis-Light';
    margin: 3em 10vw;
    font-size: 1rem;
    text-align: center;
    line-height: 1.6em;
    animation: name duration timing-function delay iteration-count direction fill-mode;
}

.read-more{
    position: relative;
    font-size: 0.8rem;
    text-align: center;
    z-index: 3;
}
.read-more a{
    position: relative;
    text-decoration: none;
    color: #fff;
    padding: 1em 4em 1em 1em;
    font-family: 'Metropolis-Light';
    letter-spacing: 0.2em;
}
.read-more .text{
    position: relative;
    z-index: 5;
}
.read-more .circul{
    background: linear-gradient(29deg,#2B47F0 24% ,#DD4FE9 75% );
    padding: 1.5em 1.5em;    
    border-radius: 50vw;
    position: absolute;
    z-index: 4;
    top: 50%;
    transform: translate(0, -50%);
    right: 0em;
    width: 0%;  
    transition: width 0.5s ease-in-out;
}
.read-more .arrow{
    position: absolute;
    z-index: 5;
    top: 50%;
    transform: translate(0, -50%);
    right: 0.8em;
}
.read-more a:hover .circul{
    width: 80%;    
}

.carousel-wrapper{
    width: 100%;
    overflow: hidden;
}
.trust-us-wrapper{
    width: 80%;
    margin: 10vh auto 0 auto;
    border: 0.01em solid;
    border-image-slice: 1;
    border-width: 0.01em;
    border-image-source: linear-gradient(29deg,#2b49f055 24% ,#DD4FE955 75% );
}
.trust-us{
    position: relative;
    display: block;
    font-size: 3em;
    z-index: 4;
    color: #fff;
    font-family: 'Metropolis-black';
    text-align: center;
    margin-top: 10vh;
}
.carousel{
    position: relative;
    z-index: 3;
    margin: 10vh 0;
    width: 100%;
    margin-top: 3em;
    height: 3rem;
}
.carousel-wrapper .slick-slide{
    /* filter: opacity(0.5); */
    opacity: 0;
    transition: all 0.7s ease-in-out ;
}

/* glowing in center effect */
.carousel-wrapper .slick-active{
    opacity: 0.1;
    filter: blur(0.1em);
} 
 .carousel-wrapper .slick-active +   .slick-slide {
    opacity: 0.5;
    filter: blur(0.03em);
} 
 .carousel-wrapper .slick-active  + .slick-slide +   .slick-slide {
    opacity: 1;
    filter: blur(0em) brightness(0.1em);
} 
 .carousel-wrapper .slick-active  + .slick-slide +   .slick-slide +   .slick-slide {
    opacity: 0.5;
    filter: blur(0.03em);
} 
 .carousel-wrapper .slick-active  + .slick-slide +   .slick-slide +   .slick-slide +   .slick-slide {
    opacity: 0.1;
    filter: blur(0.1em);
} 



.carousel-wrapper .slick-slide:hover{
    filter: opacity(1);
}
.carousel-wrapper .slick-slide img{
    width: auto;
    height: 3rem;
    margin-right: 5rem;
}

.we-cerate{
    padding: 10vh 10vw 0 10vw;
}
.we-cerate .header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 3;
}

.we-cerate .header .title{
    color:#fff;
    flex: 3;
    font-family: 'Metropolis-Bold';
}

.we-cerate .header .title span{
    font-family: 'Metropolis-light';
}

.we-cerate .header .title h2{
    font-size: 7rem;
    min-width: 60vw;
    letter-spacing: 0.1em;
    margin: 0;
}

.we-cerate .header .shape{
    flex: 1;
    min-width: 15vw;
    max-width: 25vw;
}

.we-cerate .header .shape img{
    width: 100%;
    height: auto;
}

.we-cerate ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #000000;
    list-style: none;
    padding: 0;
    text-align: center;
    font-size: 3rem;
    font-family: 'Metropolis-black';
    line-height: 2em;
    text-shadow: 1px 1px white, 1px -1px white, -1px 1px white, -1px -1px white;
}



.we-cerate li a{
    position: relative;
    z-index: 4;
    cursor: pointer;
    display: flex;
    transition: color 0.5s ease-in-out;
    text-decoration: none;
}

.we-cerate li:hover{
    color: #fff;
    text-shadow: 1px 1px #000, 1px -1px #000, -1px 1px #000, -1px -1px #000;
}

.process{
     color: white;
     width: 80%;
     margin: 7rem auto;
}

.process .header{
    display: flex;
    margin-bottom: 3rem;
    justify-content: space-between;
}

.process .header h2{
    font-family: 'Metropolis-Bold';
    font-size: 3rem;
    margin: auto 0;
    position: relative;
    z-index: 4;
    letter-spacing: 0.5rem;
}

.process .mobile-screen-process{
    display: none;
}

/* // */

.we-handle-feature {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    align-items: stretch;
}

.we-handle-feature .title {
    flex: 1;
    flex-direction: column;
    display: flex;
    justify-content: space-around;
}

.we-handle-feature .title div{
    display: flex;
    align-items: center;
    position: relative;
    z-index: 4;
}

.we-handle-feature .title div img{
    height: 2rem;
}

.we-handle-feature .title div img.colored-image{
    display: none;
}

.we-handle-feature .title div span{
    display: inline-block;
    font-family: 'Metropolis-Light';
    padding: 1rem;
    border-radius: 0.8rem;
    font-size: 1.2rem;
    cursor: pointer;
    position: relative;
    z-index: 4;
    line-height: 2rem;
}

.we-handle-feature .title div.active{
    font-family: 'Metropolis-Bold';
    font-size: 1.6rem;
}

.we-handle-feature .title div.active img.colored-image{
    display: inline-block;
}

.we-handle-feature .title div.active img.uncolored-image{
    display: none;
}

.we-handle-feature .title div:hover img.colored-image{
    display: inline-block;
}

.we-handle-feature .title div:hover img.uncolored-image{
    display: none;
}

.we-handle-feature .title div img.colored-image{
    display: none;
}

.we-handle-feature .title p {
    font-family: 'Metropolis-Light';
    line-height: 1.5rem;
}

.we-handle-feature .description {
    font-family: 'Metropolis-Light';
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    background: linear-gradient(55.38deg, #2B47F0 17.6%, #DD4FE9 75.36%);
    flex: 1;
    display: none;
    padding: 4rem;
    border-radius: 1rem;
    position: relative;
    z-index: 3;
}

.we-handle-feature .description.active {
    display: initial;
}

.we-handle-feature .description div {
    display: flex;
    align-items: center;
}

.we-handle-feature .description div img{
    height: 3rem;
    padding-right: 1rem;
}

.we-handle-feature .description h2 {
    font-family: 'Metropolis-Bold';
}

 /* // */

 .complex-scroll-container{
     position: relative;
     overflow: hidden;
 }



 .complex-scroll-container .vertical-displacement{
     opacity: 0;
     position: relative;
     top: 9vh;
     background-color: #fff;
     width: 100vw;
     height: 1000vw;
 }
 .complex-scroll-container .scroll{
    position: absolute;
    width: 100vw;
    top: 9vh;
    left: 0;
    /* height: 91vh; */
    overflow-x: clip;
    overflow-y: hidden;
}
 .complex-scroll-container .horizontal-content{
    width: 700vw;
    height: 91vh;
    display: flex;
}
.complex-scroll-container .scroll.position-fix{
    position: fixed;
    z-index: 4;
    top: 9vh;
    overflow-x: scroll;
}
/* .complex-scroll-container .horizontal-content.position-fix{
    position: fixed;
    z-index: 4;
} */
.complex-scroll-container .scroll.position-bottom{
    top: initial;
    left: initial;
    bottom: 0;
    right: 0;
}
/* .complex-scroll-container .horizontal-content.position-bottom{
    top: initial;
    left: initial;
    bottom: 0;
    right: 0;
} */


.complex-scroll-container .detector{
    position: absolute;
    bottom: 100vh;
}
.complex-scroll-container .horizontal-content > div{
    position: relative;
    border-left: 0.1em solid #c4c4c480;
    height: 100%;
    width: 25vw;
    background: #fff;
    z-index: 4;
}
.complex-scroll-container .scroll.no-hover  .horizontal-content > div{
    pointer-events: none;
}

.complex-scroll-container .horizontal-content div h2{
    font-family: 'Metropolis-Bold';
    position: relative;
    display: block;
    text-align: center;
    left: 0%;
    top: 50%;
    transform: translate(0%,-50%);
    letter-spacing: 0.15em;
    margin-bottom: 0.1em;
    transition: all 0.3s 0.1s ease-in-out, color 0s;
    color: #000;
}
.complex-scroll-container .horizontal-content div span{
    font-family: 'Metropolis-light';
    position: relative;
    display: block;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    letter-spacing: 0.1em;
    font-size: 0.8em;
    transition: all 0.3s 0.05s ease-in-out, color 0s;
    color: #000;
}
.complex-scroll-container .horizontal-content div .image{
    position: absolute;
    left: 0;
    bottom: 0%;
    opacity: 0;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
}

.complex-scroll-container .horizontal-content .start{
    background: linear-gradient(69deg,#2B47F0 24% ,#DD4FE9 75% );
}
.complex-scroll-container .horizontal-content .start h2{
    color: #fff;
    font-size: 2rem;
}

.complex-scroll-container .horizontal-content .end{
    background: linear-gradient(69deg,#2B47F0 24% ,#DD4FE9 75% );
}
.complex-scroll-container .horizontal-content .end a{
    color: #fff;
    font-size: 2rem;
    font-family: 'Metropolis-Bold';
    position: relative;
    display: block;
    text-align: center;
    left: 0%;
    top: 50%;
    transform: translate(0%,-50%);
    letter-spacing: 0.15em;
    text-decoration: none;
}

.complex-scroll-container .horizontal-content .portfolio:hover h2,
.complex-scroll-container .horizontal-content .portfolio:active h2,
.complex-scroll-container .horizontal-content .portfolio.force h2{
    color: #fff;
    top: 15%;
    transform: translate(0%,0%);
    transition: all 0.3s ease-in-out;
}
.complex-scroll-container .horizontal-content .portfolio:hover span,
.complex-scroll-container .horizontal-content .portfolio:active span,
.complex-scroll-container .horizontal-content .portfolio.force span{
    color: #fff;
    top: 15%;
    transform: translate(-50%,0%);
    transition: all 0.3s 0.05s ease-in-out;
}
.complex-scroll-container .horizontal-content .portfolio:hover .image,
.complex-scroll-container .horizontal-content .portfolio:active .image,
.complex-scroll-container .horizontal-content .portfolio.force .image{
    opacity: 1;
    transform: scale(1);
    transition: all 0.3s 0.05s ease-in-out;
    pointer-events: none;
    width: 100%;
}

.complex-scroll-container .horizontal-content div img{
    width: 100%;
    position: relative;
    left: 50%;
    transform: translate(-50%,0%);
}


.complex-scroll-container .horizontal-content div:nth-child(2):hover,
.complex-scroll-container .horizontal-content div:nth-child(2):active,
.complex-scroll-container .horizontal-content div:nth-child(2).force{
    background: linear-gradient(180deg,#F2F58A 0% ,#a1a603d4 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(3):hover,
.complex-scroll-container .horizontal-content div:nth-child(3):active,
.complex-scroll-container .horizontal-content div:nth-child(3).force{
    background: linear-gradient(180deg,#C33C2B 0% ,#1D2E70 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(4):hover,
.complex-scroll-container .horizontal-content div:nth-child(4):active,
.complex-scroll-container .horizontal-content div:nth-child(4).force{
    background: linear-gradient(180deg,#26549F 0% ,#377AF5 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(5):hover,
.complex-scroll-container .horizontal-content div:nth-child(5):active,
.complex-scroll-container .horizontal-content div:nth-child(5).force{
    background: linear-gradient(180deg,#000 0% ,#0D0202 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(6):hover,
.complex-scroll-container .horizontal-content div:nth-child(6):active,
.complex-scroll-container .horizontal-content div:nth-child(6).force{
    background: linear-gradient(180deg,#A9803C 0% ,#66440C 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(7):hover,
.complex-scroll-container .horizontal-content div:nth-child(7):active,
.complex-scroll-container .horizontal-content div:nth-child(7).force{
    background: linear-gradient(180deg,#1A1A1A 0% ,#2C2B2B 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(8):hover,
.complex-scroll-container .horizontal-content div:nth-child(8):active,
.complex-scroll-container .horizontal-content div:nth-child(8).force{
    background: linear-gradient(180deg,#87A0C6 0% ,#202834 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(9):hover,
.complex-scroll-container .horizontal-content div:nth-child(9):active,
.complex-scroll-container .horizontal-content div:nth-child(9).force{
    background: linear-gradient(180deg,#BB273B 0% ,#F24C58 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(10):hover,
.complex-scroll-container .horizontal-content div:nth-child(10):active,
.complex-scroll-container .horizontal-content div:nth-child(10).force{
    background: linear-gradient(180deg,#F9DB66 0% ,#9F8625 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(11):hover,
.complex-scroll-container .horizontal-content div:nth-child(11):active,
.complex-scroll-container .horizontal-content div:nth-child(11).force{
    background: linear-gradient(180deg,#7E468A 0% ,#4B214E 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(12):hover,
.complex-scroll-container .horizontal-content div:nth-child(12):active,
.complex-scroll-container .horizontal-content div:nth-child(12).force{
    background: linear-gradient(180deg,#74C0B9 0% ,#3D827A 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(13):hover,
.complex-scroll-container .horizontal-content div:nth-child(13):active,
.complex-scroll-container .horizontal-content div:nth-child(13).force{
    background: linear-gradient(180deg,#5873FC 0% ,#16AEF2 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(14):hover,
.complex-scroll-container .horizontal-content div:nth-child(14):active,
.complex-scroll-container .horizontal-content div:nth-child(14).force{
    background: linear-gradient(180deg,#00BFFF 0% ,#53606C 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(15):hover,
.complex-scroll-container .horizontal-content div:nth-child(15):active,
.complex-scroll-container .horizontal-content div:nth-child(15).force{
    background: linear-gradient(180deg,#CC0843 0% ,#81052A 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(16):hover,
.complex-scroll-container .horizontal-content div:nth-child(16):active,
.complex-scroll-container .horizontal-content div:nth-child(16).force{
    background: linear-gradient(180deg,#A5D270 0% ,#8DBF5A 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(17):hover,
.complex-scroll-container .horizontal-content div:nth-child(17):active,
.complex-scroll-container .horizontal-content div:nth-child(17).force{
    background: linear-gradient(180deg,#FF5800 0% ,#FF5800 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(18):hover,
.complex-scroll-container .horizontal-content div:nth-child(18):active,
.complex-scroll-container .horizontal-content div:nth-child(18).force{
    background: linear-gradient(180deg,#A97F4D 0% ,#9C6021 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(19):hover,
.complex-scroll-container .horizontal-content div:nth-child(19):active,
.complex-scroll-container .horizontal-content div:nth-child(19).force{
    background: linear-gradient(180deg,#2A3177 0% ,#171D58 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(20):hover,
.complex-scroll-container .horizontal-content div:nth-child(20):active,
.complex-scroll-container .horizontal-content div:nth-child(20).force{
    background: linear-gradient(180deg,#F2A975 0% ,#FECAA5 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(21):hover,
.complex-scroll-container .horizontal-content div:nth-child(21):active,
.complex-scroll-container .horizontal-content div:nth-child(21).force{
    background: linear-gradient(180deg,#BFD840 0% ,#63721B 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(22):hover,
.complex-scroll-container .horizontal-content div:nth-child(22):active,
.complex-scroll-container .horizontal-content div:nth-child(22).force{
    background: linear-gradient(180deg,#2AB7C7 0% ,#0F0F0F 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(23):hover,
.complex-scroll-container .horizontal-content div:nth-child(23):active,
.complex-scroll-container .horizontal-content div:nth-child(23).force{
    background: linear-gradient(180deg,#CBBE9D 0% ,#CDC09D 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(24):hover,
.complex-scroll-container .horizontal-content div:nth-child(24):active,
.complex-scroll-container .horizontal-content div:nth-child(24).force{
    background: linear-gradient(180deg,#706985 0% ,#4E46 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(25):hover,
.complex-scroll-container .horizontal-content div:nth-child(25):active,
.complex-scroll-container .horizontal-content div:nth-child(25).force{
    background: linear-gradient(180deg,#000000 0% ,#FDE633 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(26):hover,
.complex-scroll-container .horizontal-content div:nth-child(26):active,
.complex-scroll-container .horizontal-content div:nth-child(26).force{
    background: linear-gradient(180deg,#9D4204 0% ,#FB6602 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(27):hover,
.complex-scroll-container .horizontal-content div:nth-child(27):active,
.complex-scroll-container .horizontal-content div:nth-child(27).force{
    background: linear-gradient(180deg,#9D4204 0% ,#FB6602 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(28):hover,
.complex-scroll-container .horizontal-content div:nth-child(28):active,
.complex-scroll-container .horizontal-content div:nth-child(28).force{
    background: linear-gradient(180deg,#DC3D3D 0% ,#2B3B57 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(29):hover,
.complex-scroll-container .horizontal-content div:nth-child(29):active,
.complex-scroll-container .horizontal-content div:nth-child(29).force{
    background: linear-gradient(180deg,#79D083 0% ,#2B3B57 100% );
}
.complex-scroll-container .horizontal-content div:nth-child(29):hover,
.complex-scroll-container .horizontal-content div:nth-child(29):active,
.complex-scroll-container .horizontal-content div:nth-child(29).force{
    background: linear-gradient(180deg,#8881E3 0% ,#FFDF5C 100% );
}

@media (pointer: coarse) {
    .complex-scroll-container .horizontal-content .portfolio.force-touch-secreen h2{
        color: #fff;
        top: 15%;
        transform: translate(0%,0%);
        transition: all 0.3s ease-in-out;
    }
    .complex-scroll-container .horizontal-content .portfolio.force-touch-secreen span{
        color: #fff;
        top: 15%;
        transform: translate(-50%,0%);
        transition: all 0.3s 0.05s ease-in-out;
    }
    .complex-scroll-container .horizontal-content .portfolio.force-touch-secreen .image{
        opacity: 1;
        transform: scale(1);
        transition: all 0.3s 0.05s ease-in-out;
        pointer-events: none;
    }

    .complex-scroll-container .horizontal-content div:nth-child(2):hover,
    .complex-scroll-container .horizontal-content div:nth-child(2):active,
    .complex-scroll-container .horizontal-content div:nth-child(2).force-touch-secreen{
        background: linear-gradient(180deg,#F2F58A 0% ,#a1a603d4 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(3):hover,
    .complex-scroll-container .horizontal-content div:nth-child(3):active,
    .complex-scroll-container .horizontal-content div:nth-child(3).force-touch-secreen{
        background: linear-gradient(180deg,#C33C2B 0% ,#1D2E70 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(4):hover,
    .complex-scroll-container .horizontal-content div:nth-child(4):active,
    .complex-scroll-container .horizontal-content div:nth-child(4).force-touch-secreen{
        background: linear-gradient(180deg,#26549F 0% ,#377AF5 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(5):hover,
    .complex-scroll-container .horizontal-content div:nth-child(5):active,
    .complex-scroll-container .horizontal-content div:nth-child(5).force-touch-secreen{
        background: linear-gradient(180deg,#000 0% ,#0D0202 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(6):hover,
    .complex-scroll-container .horizontal-content div:nth-child(6):active,
    .complex-scroll-container .horizontal-content div:nth-child(6).force-touch-secreen{
        background: linear-gradient(180deg,#A9803C 0% ,#66440C 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(7):hover,
    .complex-scroll-container .horizontal-content div:nth-child(7):active,
    .complex-scroll-container .horizontal-content div:nth-child(7).force-touch-secreen{
        background: linear-gradient(180deg,#1A1A1A 0% ,#2C2B2B 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(8):hover,
    .complex-scroll-container .horizontal-content div:nth-child(8):active,
    .complex-scroll-container .horizontal-content div:nth-child(8).force-touch-secreen{
        background: linear-gradient(180deg,#87A0C6 0% ,#202834 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(9):hover,
    .complex-scroll-container .horizontal-content div:nth-child(9):active,
    .complex-scroll-container .horizontal-content div:nth-child(9).force-touch-secreen{
        background: linear-gradient(180deg,#BB273B 0% ,#F24C58 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(10):hover,
    .complex-scroll-container .horizontal-content div:nth-child(10):active,
    .complex-scroll-container .horizontal-content div:nth-child(10).force-touch-secreen{
        background: linear-gradient(180deg,#F9DB66 0% ,#9F8625 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(11):hover,
    .complex-scroll-container .horizontal-content div:nth-child(11):active,
    .complex-scroll-container .horizontal-content div:nth-child(11).force-touch-secreen{
        background: linear-gradient(180deg,#7E468A 0% ,#4B214E 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(12):hover,
    .complex-scroll-container .horizontal-content div:nth-child(12):active,
    .complex-scroll-container .horizontal-content div:nth-child(12).force-touch-secreen{
        background: linear-gradient(180deg,#74C0B9 0% ,#3D827A 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(13):hover,
    .complex-scroll-container .horizontal-content div:nth-child(13):active,
    .complex-scroll-container .horizontal-content div:nth-child(13).force-touch-secreen{
        background: linear-gradient(180deg,#5873FC 0% ,#16AEF2 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(14):hover,
    .complex-scroll-container .horizontal-content div:nth-child(14):active,
    .complex-scroll-container .horizontal-content div:nth-child(14).force-touch-secreen{
        background: linear-gradient(180deg,#00BFFF 0% ,#53606C 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(15):hover,
    .complex-scroll-container .horizontal-content div:nth-child(15):active,
    .complex-scroll-container .horizontal-content div:nth-child(15).force-touch-secreen{
        background: linear-gradient(180deg,#CC0843 0% ,#81052A 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(16):hover,
    .complex-scroll-container .horizontal-content div:nth-child(16):active,
    .complex-scroll-container .horizontal-content div:nth-child(16).force-touch-secreen{
        background: linear-gradient(180deg,#A5D270 0% ,#8DBF5A 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(17):hover,
    .complex-scroll-container .horizontal-content div:nth-child(17):active,
    .complex-scroll-container .horizontal-content div:nth-child(17).force-touch-secreen{
        background: linear-gradient(180deg,#FF5800 0% ,#FF5800 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(18):hover,
    .complex-scroll-container .horizontal-content div:nth-child(18):active,
    .complex-scroll-container .horizontal-content div:nth-child(18).force-touch-secreen{
        background: linear-gradient(180deg,#A97F4D 0% ,#9C6021 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(19):hover,
    .complex-scroll-container .horizontal-content div:nth-child(19):active,
    .complex-scroll-container .horizontal-content div:nth-child(19).force-touch-secreen{
        background: linear-gradient(180deg,#2A3177 0% ,#171D58 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(20):hover,
    .complex-scroll-container .horizontal-content div:nth-child(20):active,
    .complex-scroll-container .horizontal-content div:nth-child(20).force-touch-secreen{
        background: linear-gradient(180deg,#F2A975 0% ,#FECAA5 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(21):hover,
    .complex-scroll-container .horizontal-content div:nth-child(21):active,
    .complex-scroll-container .horizontal-content div:nth-child(21).force-touch-secreen{
        background: linear-gradient(180deg,#BFD840 0% ,#63721B 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(22):hover,
    .complex-scroll-container .horizontal-content div:nth-child(22):active,
    .complex-scroll-container .horizontal-content div:nth-child(22).force-touch-secreen{
        background: linear-gradient(180deg,#2AB7C7 0% ,#0F0F0F 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(23):hover,
    .complex-scroll-container .horizontal-content div:nth-child(23):active,
    .complex-scroll-container .horizontal-content div:nth-child(23).force-touch-secreen{
        background: linear-gradient(180deg,#CBBE9D 0% ,#CDC09D 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(24):hover,
    .complex-scroll-container .horizontal-content div:nth-child(24):active,
    .complex-scroll-container .horizontal-content div:nth-child(24).force-touch-secreen{
        background: linear-gradient(180deg,#706985 0% ,#4E46 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(25):hover,
    .complex-scroll-container .horizontal-content div:nth-child(25):active,
    .complex-scroll-container .horizontal-content div:nth-child(25).force-touch-secreen{
        background: linear-gradient(180deg,#000000 0% ,#FDE633 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(26):hover,
    .complex-scroll-container .horizontal-content div:nth-child(26):active,
    .complex-scroll-container .horizontal-content div:nth-child(26).force-touch-secreen{
        background: linear-gradient(180deg,#9D4204 0% ,#FB6602 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(27):hover,
    .complex-scroll-container .horizontal-content div:nth-child(27):active,
    .complex-scroll-container .horizontal-content div:nth-child(27).force-touch-secreen{
        background: linear-gradient(180deg,#9D4204 0% ,#FB6602 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(28):hover,
    .complex-scroll-container .horizontal-content div:nth-child(28):active,
    .complex-scroll-container .horizontal-content div:nth-child(28).force-touch-secreen{
        background: linear-gradient(180deg,#DC3D3D 0% ,#2B3B57 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(29):hover,
    .complex-scroll-container .horizontal-content div:nth-child(29):active,
    .complex-scroll-container .horizontal-content div:nth-child(29).force-touch-secreen{
        background: linear-gradient(180deg,#79D083 0% ,#2B3B57 100% );
    }
    .complex-scroll-container .horizontal-content div:nth-child(29):hover,
    .complex-scroll-container .horizontal-content div:nth-child(29):active,
    .complex-scroll-container .horizontal-content div:nth-child(29).force-touch-secreen{
        background: linear-gradient(180deg,#8881E3 0% ,#FFDF5C 100% );
    }   
}

 @supports (-webkit-text-stroke-width: 1px) and ( -webkit-text-stroke-color: #fff) {
    .we-cerate ul a{
        color: transparent;
        -webkit-text-stroke-width: 0.015em;
        -webkit-text-stroke-color: #fff;
        text-shadow:none;
    }

    .we-cerate ul li:hover a{
        color: transparent;
        -webkit-text-stroke-width: 0.015em;
        -webkit-text-stroke-color: #000;
        color: #fff;
        text-shadow:none;
    }
}
.we-cerate ul div{
    position: fixed;
    z-index: 0;
    width: 20vw;
    height: auto;
    opacity: 0;
}

.we-cerate ul div img{
    width: 100%;
}

/* so it can work only with mouse hovering */
@media screen and (hover: hover) {
    .we-cerate .img11{
        top: 10%;
        left: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 2s ease-in-out alternate infinite;
        background: url('../images/mobile-app-1.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 20rem;
        height: 20rem;
    }
    .we-cerate .img12{
        bottom: 10%;
        right: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 3s ease-in-out alternate infinite;
        background: url('../images/mobile-app-2.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 20rem;
        height: 22rem;
    }
    .we-cerate .img21{
        top: 17%;
        left: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 2s ease-in-out alternate infinite;
        background: url('../images/mobile-app-3.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 25rem;
        height: 14rem;
    }
    .we-cerate .img22{
        bottom: 17%;
        right: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 3s ease-in-out alternate infinite;
        background: url('../images/mobile-app-4.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 25rem;
        height: 14rem;
    }
    .we-cerate .img31{
        top: 24%;
        left: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 2s ease-in-out alternate infinite;
        background: url('../images/mobile-app-5.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 20rem;
        height: 18rem;
    }
    .we-cerate .img32{
        bottom: 24%;
        right: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 3s ease-in-out alternate infinite;
        background: url('../images/mobile-app-6.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 20rem;
        height: 18rem;
    }
    .we-cerate .img41{
        top: 31%;
        left: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 2s ease-in-out alternate infinite;
        background: url('../images/mobile-app-7.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 20rem;
        height: 20rem;
    }
    .we-cerate .img42{
        bottom: 31%;
        right: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 3s ease-in-out alternate infinite;
        background: url('../images/mobile-app-8.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 22rem;
        height: 18rem;
    }
    .we-cerate .img51{
        top: 38%;
        left: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 2s ease-in-out alternate infinite;
        background: url('../images/mobile-app-9.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 19rem;
        height: 22rem;
    }
    .we-cerate .img52{
        bottom: 38%;
        right: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 3s ease-in-out alternate infinite;
        background: url('../images/mobile-app-10.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 14rem;
        height: 25rem;
    }
    .we-cerate .img61{
        top: 45%;
        left: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 2s ease-in-out alternate infinite;
        background: url('../images/mobile-app-11.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 22rem;
        height: 20rem;
    }
    .we-cerate .img62{
        bottom: 45%;
        right: 10vw;
        transition: all 1s ease-in-out;
        animation: hovering 3s ease-in-out alternate infinite;
        background: url('../images/mobile-app-12.webp') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 17rem;
        height: 22rem;
    }

    .mobile-apps:hover ~ .img11{
        z-index: 3;
        opacity: 1;      
    }
    .mobile-apps:hover ~ .img12{
        z-index: 3;
        opacity: 1;
    }
    .website-development:hover ~ .img21{
        z-index: 3;
        opacity: 1;      
    }
    .website-development:hover ~ .img22{
        z-index: 3;
        opacity: 1;       
    }
    .ui-ux-design:hover ~ .img31{
        z-index: 3;
        opacity: 1;   
    }
    .ui-ux-design:hover ~ .img32{
        z-index: 3;
        opacity: 1; 
    }
    .social-media:hover ~ .img41{
        z-index: 3;
        opacity: 1;   
    }
    .social-media:hover ~ .img42{
        z-index: 3;
        opacity: 1;    
    }
    .branding:hover ~ .img51{
        z-index: 3;
        opacity: 1;   
    }
    .branding:hover ~ .img52{
        z-index: 3;
        opacity: 1;
    }
    .dedicated-resources:hover ~ .img61{
        z-index: 3;
        opacity: 1;
    }
    .dedicated-resources:hover ~ .img62{
        z-index: 3;
        opacity: 1;
    }
}

@keyframes hovering {
    0%{
        transform: translate(0em, 0.7em);
    }
    100%{
        transform: translate(0em, 0.1em);
    }
}

.techs{
    background-color: #fff;
    padding-top: 4em;
    margin-top: 10em;
}

.techs span{
    display: inline-block;
    text-align: center;
    font-family: 'Metropolis-Bold';
    font-size: 2em;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 4;
}


@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
    .omg .slot-word .letter-wrapper > span{ 
        background-image: linear-gradient(29deg,#2B47F0 24% ,#DD4FE9 75% );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
    }

    .we-cerate .header .title span{
        background-image: linear-gradient(29deg,#2B47F0 24% ,#DD4FE9 75% );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; 
    }

}

.footer-carousel-wrapper {
    width: 100%;
    overflow: hidden;
}

.footer-carousel-wrapper .slick-slide img{
    width: auto;
    height: 3rem;
    margin-right: 5rem;}

/* 990px and below */
@media screen and (max-width: 990px) {
    .complex-scroll-container .vertical-displacement{
        height: 1600vw;
    }
    .complex-scroll-container .horizontal-content{
        width: 1680vw;
    }

    .complex-scroll-container .horizontal-content > div{
        width: 60vw;
    }
}


@media screen and (max-width: 672px) {

    .process .header h2{
        margin: auto;
        text-align: center;
    }

    .process .desktop-screen-process{
        display: none;
    }

    .process .mobile-screen-process{
        display: block;
    }

    .process .header img{
        width: 20%;
        height: fit-content;
        position: relative;
        z-index: 4;
        margin: auto 0;
    }
    
     .process .list-wrapper{
        position: relative;
     }
    
     .process .list-wrapper .side-line{
        height: 86%;
        width: 0.05rem;
        position: absolute;
        z-index: 5;
        left: 0;
        top: 7%;
        background-color: #DD4FE9;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
     }
    
     .process .list-wrapper .side-line > div{
        flex: 0;
        padding: 0.325rem;
        border-radius: 100%;
        margin-left: -0.3rem;
        background: linear-gradient(69deg,#2B47F0 24% ,#DD4FE9 75% );
     }
    
     .process .list-wrapper .list>div{
        display: flex;
        position: relative;
        flex-wrap: wrap;
        padding: 1rem 0px;
     }
    
     .process .list-wrapper .list>div:not(:last-child):after {
        content: '';
        width: 98%;
        height: 0.01rem;
        background: #dc4fe93c;
        position: absolute;
        bottom: 0px;
        left: 2%;
      }
    
     .process .list-wrapper .list div img{
        flex: 1;
        width: 8%;
        height: fit-content;
        margin: auto 1rem;
        position: relative;
        z-index: 5;
     }
    
     .process .list-wrapper .list div h5{
        flex: 4;
        font-family: 'Metropolis-light';
        margin: auto;
        position: relative;
        z-index: 5;
        font-size: 1.2rem;
    }
    
    .process .list-wrapper .list div p{
        flex: 8;
        font-family: 'Metropolis-light';
        font-size: 1rem;
        line-height: 1.2rem;
        margin: auto;
        margin-left: 2rem;
        position: relative;
        z-index: 5;
        text-align: justify;
        padding: 1rem 0;
    }
    /*  */
    .we-cerate .header .title h2 {
        font-size: 5rem;
    }

    .process .header img{
        display: none;
    }

    .process .list-wrapper .side-line{
        display: none;
    }

    .process .list-wrapper .list>div{
        flex-direction: column;
        margin-bottom: 1rem;
    }

    .process .list-wrapper .list>div:after {
        content: '';
        width: 100%;
        left: 0%;
    }

    .process .list-wrapper .list div img{
        margin: auto;
        width: 25%;
    }

    .process .list-wrapper .list div h5{
        font-size: 1.3rem;
        text-align: center;
        margin-bottom: 1rem;
        margin-top: 0.8rem;
        font-family: 'Metropolis-bold';
    }

    .process .list-wrapper .list div p{
        padding-bottom: 2rem;
        margin: 0;
        text-align: center;
    }

    .complex-scroll-container .vertical-displacement{
        height: 2000vw;
    }

    .complex-scroll-container .horizontal-content{
        width: 2240vw;
    }

    .complex-scroll-container .horizontal-content > div{
        width: 80vw;
    }
}

@media screen and (max-width: 300px) {
    .viewport-one .wraper {
        position: relative;
        display: inline-block;
        z-index: 3;
        top: 50%;
        left: 49%;
        width: 75%;
        transform: translate(-50%,-50%);
        overflow: hidden;
    }
    .complex-scroll-container .vertical-displacement{
        height: 2500vw;
    }

    .complex-scroll-container .horizontal-content{
        width: 2800vw;
    }

    .complex-scroll-container .horizontal-content > div{
        width: 100vw;
    }
}

